<template>
  <div class="only-header-layout">
    <MyAccountClinician />
    <ClinicianNavbar
      :user="user"
      :no-sidebar="true"
    />
    <div class="view-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import ClinicianNavbar from './ClinicianNavbar'
import MyAccountClinician from '@/components/clinician/MyAccountClinician'

import { mapGetters } from 'vuex'

export default {
  name: 'OnlyHeaderLayout',
  // Todo - https://owloutcomes.atlassian.net/browse/OWL-8407
  // beforeDestroy () {
  //   document.body.classList.remove('body-only-header-layout')
  // },
  components: {
    ClinicianNavbar,
    MyAccountClinician
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      user: 'loggedInUser'
    })
  },
  beforeCreate () {
    document.body.className = 'body-only-header-layout'
  }
}
</script>
<style lang="scss">
  @import './../../assets/styles/override.scss';
  .body-only-header-layout {
    .only-header-layout {
      -ms-grid-rows: $navbarHeight auto;
      grid-template-rows: $navbarHeight auto;
      .view-container {
        height: 100%;
        grid-row: 2;
      }
    }
  }
</style>
